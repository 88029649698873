body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* global styles */
.logo-image {
  object-fit: contain;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-grid-layout {
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #a996ff;
  font-size: inherit;
  -webkit-box-shadow: none !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: none;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: rgb(132, 124, 196);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 5px 5px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.MuiSkeleton-root {
  border-radius: 4px;
}

.MuiAutocomplete-endAdornment {
  height: 25px;
  display: flex;
  align-items: center;
}

.MuiSelect-root div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker-cluster-small {
  background-color: #a6f787;
}

.marker-cluster-medium {
  background-color: #ffdf9a;
}

.marker-cluster-large {
  background-color: #ff75cb;
}

.leaflet-marker-icon {
  color: #14142b;
  border-radius: 50%;
  object-fit: cover;
}

.leaflet-control-zoom {
  border-radius: 10px;
  background-color: #d9dbe9;
  border: none !important;
  box-shadow: 2px 4px 12px rgb(20 20 43 / 4%);
}

.leaflet-touch .leaflet-bar a:first-child {
  background: transparent;
  color: #4e4b66;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  background: transparent;
  color: #4e4b66;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none !important;
}

/*reacttour*/
.gbNdNi {
  background-color: #a996ff !important;
}

.show-more-text-content {
  white-space: pre-line;
}

.show-more-text-content-testimonial {
  white-space: pre-line;
  color: #6e7191;
  font-size: 13px;
  font-weight: 500;
}

.my-anchor-css-class {
  color: #610bef;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.my-anchor-css-class-dark {
  color: #a996ff;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

/*Data Grid*/

.MuiDataGrid-columnHeaderTitle {
  font-size: 12px;
}

.MuiDataGrid-cell {
  font-size: 13px;
  overflow-y: auto !important;
}

.not-app-link {
  color: #a996ff;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.leaflet-bottom {
  display: none;
}

.leaflet-draw-section {
  display: none;
}

.sound-wave {
  width: 70%;
  margin: 0 auto;
  height: 56px;
  border-radius: 26px;
}

.sound-wave-mobile {
  width: calc(100% - 60px);
  margin: 0 auto;
  height: 56px;
  border-radius: 26px;
}
.erd_scroll_detection_container {
  opacity: 0;
}
.multiple-autocomplete {
  width: 100%;
}
